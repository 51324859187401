import { useBemCN } from '@gik/core/utils/bemBlock';
import type { Placement } from '@popperjs/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

export type PopperPlacement = Placement;

export type PopoverRenderCallback = (props: PopoverRenderProps) => React.ReactNode;

const blockName = `popover`;

export interface PopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  trigger: React.ReactNode | (() => React.ReactNode);
  triggerOnClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  triggerOnMouseEnter?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  isOpen?: boolean;
  initialIsOpen?: boolean;
  hoverable?: boolean;
  overlap?: boolean;
  noPad?: boolean;
  hasArrowIndicator?: boolean;
  overflowVisible?: boolean;
  onHover?(): void;
  onClose?(): void;
  children: React.ReactNode;
  portal?: HTMLElement;
  placement?: Placement;
  modifiers?: object[];
}
export type PopoverRenderProps = {
  close?: () => void;
};

export function Popover({
  isOpen,
  initialIsOpen = false,
  noPad,
  className,
  hoverable,
  overlap = false,
  onHover,
  onClose,
  trigger,
  triggerOnClick,
  triggerOnMouseEnter,
  hasArrowIndicator = true,
  overflowVisible = true,
  children,
  portal,
  placement = 'bottom',
  modifiers = [],
}: React.PropsWithChildren<PopoverProps>): React.ReactElement {
  const bem = useBemCN(blockName);

  // only for uncontrolled component
  const [_isOpen, _setIsOpen] = React.useState<boolean>(initialIsOpen);

  const controlled = isOpen !== undefined;

  const [popoverReferenceElement, setPopoverReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  const { styles, attributes } = usePopper(popoverReferenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, ...modifiers],
  });

  const onPopperClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation();

  function handleClose(ev?: React.MouseEvent<HTMLDivElement>) {
    ev?.stopPropagation();
    if (!controlled) _setIsOpen(false);
    onClose();
  }

  function handleClick(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!controlled) _setIsOpen(true);
    return triggerOnClick?.(ev);
  }

  const renderProps: PopoverRenderProps = {
    close: handleClose,
  };

  // if children is not a valid react element we assume it to be a callback function that accepts renderProps
  let _children = children;
  if (_children && !React.isValidElement(_children) && !Array.isArray(_children)) {
    _children = (_children as PopoverRenderCallback)(renderProps);
  }

  const popperElementDiv = (
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      {...bem(
        'popper',
        [
          { nopad: noPad },
          { overlap },
          { arrow: _children && hasArrowIndicator },
          { ['overflow-visible']: overflowVisible },
        ],
        className
      )}
      onClick={onPopperClick}
    >
      <div {...bem('popper-content')}>{_children}</div>
      {_children && hasArrowIndicator && <div ref={setArrowElement} style={styles.arrow} {...bem('arrow')} />}
    </div>
  );

  const isOpenValue = controlled ? isOpen : _isOpen;

  const portalContainer = (
    <div {...bem('portal', [{ open: isOpenValue }])} onClick={handleClose}>
      {popperElementDiv}
    </div>
  );

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    triggerOnMouseEnter?.(e);
    onHover?.();
  };

  const handleMouseLeave = () => {
    if (hoverable) {
      onClose?.();
    }
  };

  return (
    <div
      ref={setPopoverReferenceElement}
      {...bem('reference-element')}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {
        // eslint-disable-next-line
        typeof trigger === 'function' ? trigger() : trigger
      }
      {isOpenValue && (hoverable ? popperElementDiv : ReactDOM.createPortal(portalContainer, portal || document.body))}
    </div>
  );
}

export default {
  home: '/',
  create: '/create',
  userProfile: '/user/profile',
  features: '/features',
  benefits: '/benefits',
  tipsAndSamplePages: '/tips-sample-pages',
  competitors: '/competitors',
  commonQuestions: '/faq',
  howItWork: '/how-inkind-works',
  about: '/about-us',
  contact: '/contact',
  contactByMail: 'mailto:info@giveinkind.com',
  giveBySituation: '/give/by/situation',
  inkinds: '/inkinds',
  groups: '/groups',
  groupAdmin: '/groups/admin',
  give: '/give',
  products: '/products',
  blog: '/articles',
  walletGift: (customerId: string, giftId: string) => `/wallets/${customerId}/gift/${giftId}`,
  giftGuides: '/articles/general-results/article-type/buying-guide',
  byGiftType: '/give/by/category',
  termsService: '/terms-service',
  privacyPolicy: '/privacy-policy',
  support: 'https://help.giveinkind.com/en',
  healthcare: 'http://partners.giveinkind.com/healthcare',
  healthcareGroup: 'http://groups.giveinkind.com/healthcare',
  employers: 'http://groups.giveinkind.com/employers',
  church: 'http://partners.giveinkind.com/church',
  inAppLogin: '/in-app-login',
  resetPassword: '/reset-password',
  userWallet: '/wallets',

  mealTrain: '/set-up-meal-train',
  organizeGiftCard: '/organize-gift-card-drive',
  fundraising: '/free-fundraising',
  updates: '/share-updates',
  forGroups: '/organize-care-group',
  forNonProfits: '/organize-donation-drive',

  giftCard: '/donate-gift-cards',
};
